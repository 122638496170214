<template>
  <div class="flex flex-col">
    <AppHeader />
    <div>
      <div
        class="2xl:container"
        style="min-height: calc(100vh - 8.25rem);"
      >
        <div class="flex flex-1 flex-col md:px-6">
          <main
            class="relative flex-1 px-4 focus:outline-none md:px-0"
            tabindex="0"
          >
            <div class="py-6">
              <BaseBreadcrumbs />
              <slot />
            </div>
          </main>
        </div>
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import {DateTime} from 'luxon';

export default {
  setup() {
    const {signOut} = useAuth();

    return {
      breadcrumbs: useBreadcrumbsStore(),
      signOut,
      welcome: useSofieSetting('welkomsttekst'),
    };
  },
  data() {
    return {
      open: false,
      profilePanel: false,
      hours: DateTime.now().hour,
      interval: null,
    };
  },
  computed: {
    greetings() {
      if (this.hours < 6) {
        return 'Goedenacht';
      } else if (this.hours < 12) {
        return 'Goedemorgen';
      } else if (this.hours < 18) {
        return 'Goedemiddag';
      } else {
        return 'Goedenavond';
      }
    },
  },
  watch: {
    $route() {
      this.open = false;
      this.$nextTick(() => {
        this.checkForTitle();

        setTimeout(() => {
          this.checkForTitle();
        }, 100);
        setTimeout(() => {
          this.checkForTitle();
        }, 500);
        setTimeout(() => {
          this.checkForTitle();
        }, 1000);
      });
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.hours = DateTime.now().hour;
    }, 10000);

    this.checkForTitle();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async logout() {
      this.signOut();
      this.profilePanel = false;
    },
    clickOutside() {
      if (this.profilePanel) {
        this.profilePanel = false;
      }
    },
    checkForTitle() {
      const component = this.$refs.page && this.$refs.page.$children[0];

      this.breadcrumbs.title = component?.$metaInfo?.title || null;
    },
  },
};
</script>
